import React, { useState } from "react"
import { Link } from "gatsby"
import logo from "../img/logo.png"

const Navigation = () => {
  const [menuState, setmenuState] = useState("close")
  const MenuOpen = () => {
    if (menuState === "open") {
      setmenuState("close")
    } else {
      setmenuState("open")
    }
    //console.log(menuState)
  }
  const [scrollState, setscrollState] = useState("top")
  const handleScroll = () => {
    if (window.scrollY === 0) {
      setscrollState("top")
    } else if (window.scrollY !== 0) {
      setscrollState("onmoving")
    }
  }
  if (typeof window !== "undefined") {
    window.addEventListener("scroll", handleScroll)
  }

  return (
    <div>
      <header className={"header " + scrollState}>
        <div className="container-fluid">
          <nav className="navbar navbar-expand-lg navbar-light">
            <Link className="navbar-brand logo center-logo" to="/">
              <img src={logo} alt="Represent Corporate Giftings" />
            </Link>
            <button
              className="navbar-toggler"
              name="mobilenavigation"
              type="button"
              name="MobileNavigation"
              onClick={() => {
                MenuOpen("All")
              }}
            >
              <i className="fa fa-navicon"></i>
            </button>

            <div className="collapse navbar-collapse" id="navbarToggler">
              <ul className="navbar-nav ml-auto">
                <li className="nav-item">
                  <Link
                    to="/about-us/"
                    className="nav-link teamLinksNav"
                    style={{ fontSize: "0.9rem" }}
                  >
                    ABOUT US
                  </Link>
                </li>
                <li className="nav-item dropdown mr-auto">
                  <a
                    href="#"
                    className="dropdown-toggle nav-link"
                    data-toggle="dropdown"
                    aria-expanded="false"
                    style={{ fontSize: "0.9rem" }}
                  >
                    PRODUCTS
                  </a>
                  <ul className="dropdown-menu">
                    <li>
                      <Link
                        to="/apparel/"
                        title="APPAREL"
                        style={{ fontSize: "0.9rem" }}
                      >
                        APPAREL
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/bags/"
                        title="BAGS"
                        style={{ fontSize: "0.9rem" }}
                      >
                        BAGS
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/drinkware/"
                        title="DRINKWARE"
                        style={{ fontSize: "0.9rem" }}
                      >
                        DRINKWARE
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/office-utility/"
                        title="OFFICE UTILITY"
                        style={{ fontSize: "0.9rem" }}
                      >
                        OFFICE UTILITY
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/accessories/"
                        title="ACCESSORIES"
                        style={{ fontSize: "0.9rem" }}
                      >
                        ACCESSORIES
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/home-utilities/"
                        title="HOME UTILITIES"
                        style={{ fontSize: "0.9rem" }}
                      >
                        HOME UTILITIES
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/gadgets/"
                        title="GADGETS"
                        style={{ fontSize: "0.9rem" }}
                      >
                        GADGETS
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/fitness-wellbeing"
                        title="FITNESS & WELLBEING"
                        style={{ fontSize: "0.9rem" }}
                      >
                        FITNESS & WELLBEING
                      </Link>
                    </li>
                  </ul>
                </li>

                <li className="nav-item">
                  <Link
                    to="/contact-us/"
                    className="nav-link"
                    style={{ fontSize: "0.9rem" }}
                  >
                    CONTACT US
                  </Link>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </header>
      <div className={"Nav mobile-only " + menuState}>
        <div className="text-right">
          <button
            className="navbar-toggler"
            name="MobileNavigation"
            type="button"
            onClick={() => {
              MenuOpen("All")
            }}
          >
            <i className="fa fa-close"></i>
          </button>
        </div>
        <ul className="navbar-nav ml-auto">
          <li className="nav-item">
            <Link
              to="/about-us/"
              className="nav-link teamLinksNav"
              style={{ fontSize: "0.9rem" }}
            >
              ABOUT US
            </Link>
          </li>
          <li className="nav-item">
            <Link
              to="/products/"
              className="nav-link teamLinksNav"
              style={{ fontSize: "0.9rem" }}
            >
              PRODUCTS
            </Link>
          </li>

          <li className="nav-item">
            <Link
              to="/contact-us/"
              className="nav-link"
              style={{ fontSize: "0.9rem" }}
            >
              CONTACT US
            </Link>
          </li>
        </ul>

        <br></br>
        <Link className="navbar-brand logo" to="/">
          <img src={logo} alt="Represent Corporate Giftings" />
        </Link>
      </div>
    </div>
  )
}

export default Navigation
