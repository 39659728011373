import React from "react"
import { Link } from "gatsby"
import logo from "../img/logo.png"
const Footer = () => {
  return (
    <footer>
      <div className="container">
        <div className="row">
          <div className="col-md-12 text-center">
            <br></br>
            <img src={logo} width="230" alt="Represent Corporate"></img>
            <br />
            <br />
            <p style={{ fontSize: 19 }}>
              Budding entrepreneurs with a team of young minds having a handful
              of experience in advertising & operations to ensure a perfect
              combo of branding and execution.
            </p>
            <br></br>
            <br></br> <br></br>
          </div>
        </div>
      </div>
      <div className="copyright">
        <p>&copy; 2021 Represent Corporate Giftings</p>
      </div>
    </footer>
  )
}

export default Footer
